import { default as confirmneACjw5FXNMeta } from "/opt/buildhome/repo/pages/account/password_reset/confirm.vue?macro=true";
import { default as _19864rCLL0MiQ24Meta } from "/opt/buildhome/repo/pages/ads/19864.vue?macro=true";
import { default as _91id_93qpJwnJTAF8Meta } from "/opt/buildhome/repo/pages/blog/[id].vue?macro=true";
import { default as indexnCitm8tluDMeta } from "/opt/buildhome/repo/pages/blog/index.vue?macro=true";
import { default as _91id_93xsqeWfut0fMeta } from "/opt/buildhome/repo/pages/collection/[id].vue?macro=true";
import { default as indexhNArLQ28xLMeta } from "/opt/buildhome/repo/pages/dashboard/activities/index.vue?macro=true";
import { default as indexHMPTW2KXWYMeta } from "/opt/buildhome/repo/pages/dashboard/automation/index.vue?macro=true";
import { default as indexXEYsKLXcvSMeta } from "/opt/buildhome/repo/pages/dashboard/calendar/index.vue?macro=true";
import { default as overviewxjr44b8QXaMeta } from "/opt/buildhome/repo/pages/dashboard/collection/[id]/overview.vue?macro=true";
import { default as smartdomainsVO2W5XNZL8Meta } from "/opt/buildhome/repo/pages/dashboard/collection/[id]/smartdomains.vue?macro=true";
import { default as smartlnksZuOvhu1Q5iMeta } from "/opt/buildhome/repo/pages/dashboard/collection/[id]/smartlnks.vue?macro=true";
import { default as smartpixelsEYpMyNrmVLMeta } from "/opt/buildhome/repo/pages/dashboard/collection/[id]/smartpixels.vue?macro=true";
import { default as indexfsTvpCeUHwMeta } from "/opt/buildhome/repo/pages/dashboard/collection/index.vue?macro=true";
import { default as indexeNhtgTKAMMMeta } from "/opt/buildhome/repo/pages/dashboard/globe/index.vue?macro=true";
import { default as clicksDTg4QmaucDMeta } from "/opt/buildhome/repo/pages/dashboard/overview/clicks.vue?macro=true";
import { default as locationsmdlQfP8jkTMeta } from "/opt/buildhome/repo/pages/dashboard/overview/locations.vue?macro=true";
import { default as traffic_45sourcesc59QKVG2haMeta } from "/opt/buildhome/repo/pages/dashboard/overview/traffic-sources.vue?macro=true";
import { default as apixO9bWl0hMJMeta } from "/opt/buildhome/repo/pages/dashboard/settings/api.vue?macro=true";
import { default as connected_45appsq6oCmWd3iOMeta } from "/opt/buildhome/repo/pages/dashboard/settings/connected-apps.vue?macro=true";
import { default as domainYj9Wwc4SDlMeta } from "/opt/buildhome/repo/pages/dashboard/settings/domain.vue?macro=true";
import { default as my_45detailszf7bZ6AeRTMeta } from "/opt/buildhome/repo/pages/dashboard/settings/my-details.vue?macro=true";
import { default as notification6PDSxbMNKuMeta } from "/opt/buildhome/repo/pages/dashboard/settings/notification.vue?macro=true";
import { default as passwordVEBy2wlWKYMeta } from "/opt/buildhome/repo/pages/dashboard/settings/password.vue?macro=true";
import { default as globekaOoPZAPagMeta } from "/opt/buildhome/repo/pages/globe.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as privacy69l1Gm1VSzMeta } from "/opt/buildhome/repo/pages/privacy.vue?macro=true";
import { default as signinjIE1JUeO4jMeta } from "/opt/buildhome/repo/pages/signin.vue?macro=true";
import { default as signup71EVNEoLpMMeta } from "/opt/buildhome/repo/pages/signup.vue?macro=true";
import { default as smartytlink7oWKpfXEkxMeta } from "/opt/buildhome/repo/pages/smartytlink.vue?macro=true";
import { default as _91_46_46_46slug_93tTsflEOjmBMeta } from "/opt/buildhome/repo/pages/stats/[...slug].vue?macro=true";
import { default as termsjw1825O2ZdMeta } from "/opt/buildhome/repo/pages/terms.vue?macro=true";
import { default as testnxGb4eh78qMeta } from "/opt/buildhome/repo/pages/test.vue?macro=true";
import { default as component_45stubGZr1UkTkg3Meta } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubGZr1UkTkg3 } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "account-password_reset-confirm",
    path: "/account/password_reset/confirm",
    meta: confirmneACjw5FXNMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/password_reset/confirm.vue")
  },
  {
    name: "ads-19864",
    path: "/ads/19864",
    meta: _19864rCLL0MiQ24Meta || {},
    component: () => import("/opt/buildhome/repo/pages/ads/19864.vue")
  },
  {
    name: "blog-id",
    path: "/blog/:id()",
    component: () => import("/opt/buildhome/repo/pages/blog/[id].vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/opt/buildhome/repo/pages/blog/index.vue")
  },
  {
    name: "collection-id",
    path: "/collection/:id()",
    meta: _91id_93xsqeWfut0fMeta || {},
    component: () => import("/opt/buildhome/repo/pages/collection/[id].vue")
  },
  {
    name: "dashboard-activities",
    path: "/dashboard/activities",
    meta: indexhNArLQ28xLMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/activities/index.vue")
  },
  {
    name: "dashboard-automation",
    path: "/dashboard/automation",
    meta: indexHMPTW2KXWYMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/automation/index.vue")
  },
  {
    name: "dashboard-calendar",
    path: "/dashboard/calendar",
    meta: indexXEYsKLXcvSMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/calendar/index.vue")
  },
  {
    name: "dashboard-collection-id-overview",
    path: "/dashboard/collection/:id()/overview",
    meta: overviewxjr44b8QXaMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/collection/[id]/overview.vue")
  },
  {
    name: "dashboard-collection-id-smartdomains",
    path: "/dashboard/collection/:id()/smartdomains",
    meta: smartdomainsVO2W5XNZL8Meta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/collection/[id]/smartdomains.vue")
  },
  {
    name: "dashboard-collection-id-smartlnks",
    path: "/dashboard/collection/:id()/smartlnks",
    meta: smartlnksZuOvhu1Q5iMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/collection/[id]/smartlnks.vue")
  },
  {
    name: "dashboard-collection-id-smartpixels",
    path: "/dashboard/collection/:id()/smartpixels",
    meta: smartpixelsEYpMyNrmVLMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/collection/[id]/smartpixels.vue")
  },
  {
    name: "dashboard-collection",
    path: "/dashboard/collection",
    meta: indexfsTvpCeUHwMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/collection/index.vue")
  },
  {
    name: "dashboard-globe",
    path: "/dashboard/globe",
    meta: indexeNhtgTKAMMMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/globe/index.vue")
  },
  {
    name: "dashboard-overview-clicks",
    path: "/dashboard/overview/clicks",
    meta: clicksDTg4QmaucDMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/overview/clicks.vue")
  },
  {
    name: "dashboard-overview-locations",
    path: "/dashboard/overview/locations",
    meta: locationsmdlQfP8jkTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/overview/locations.vue")
  },
  {
    name: "dashboard-overview-traffic-sources",
    path: "/dashboard/overview/traffic-sources",
    meta: traffic_45sourcesc59QKVG2haMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/overview/traffic-sources.vue")
  },
  {
    name: "dashboard-settings-api",
    path: "/dashboard/settings/api",
    meta: apixO9bWl0hMJMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/settings/api.vue")
  },
  {
    name: "dashboard-settings-connected-apps",
    path: "/dashboard/settings/connected-apps",
    meta: connected_45appsq6oCmWd3iOMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/settings/connected-apps.vue")
  },
  {
    name: "dashboard-settings-domain",
    path: "/dashboard/settings/domain",
    meta: domainYj9Wwc4SDlMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/settings/domain.vue")
  },
  {
    name: "dashboard-settings-my-details",
    path: "/dashboard/settings/my-details",
    meta: my_45detailszf7bZ6AeRTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/settings/my-details.vue")
  },
  {
    name: "dashboard-settings-notification",
    path: "/dashboard/settings/notification",
    meta: notification6PDSxbMNKuMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/settings/notification.vue")
  },
  {
    name: "dashboard-settings-password",
    path: "/dashboard/settings/password",
    meta: passwordVEBy2wlWKYMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/settings/password.vue")
  },
  {
    name: "globe",
    path: "/globe",
    meta: globekaOoPZAPagMeta || {},
    component: () => import("/opt/buildhome/repo/pages/globe.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/opt/buildhome/repo/pages/privacy.vue")
  },
  {
    name: "signin",
    path: "/signin",
    meta: signinjIE1JUeO4jMeta || {},
    component: () => import("/opt/buildhome/repo/pages/signin.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signup71EVNEoLpMMeta || {},
    component: () => import("/opt/buildhome/repo/pages/signup.vue")
  },
  {
    name: "smartytlink",
    path: "/smartytlink",
    component: () => import("/opt/buildhome/repo/pages/smartytlink.vue")
  },
  {
    name: "stats-slug",
    path: "/stats/:slug(.*)*",
    component: () => import("/opt/buildhome/repo/pages/stats/[...slug].vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/opt/buildhome/repo/pages/terms.vue")
  },
  {
    name: "test",
    path: "/test",
    meta: testnxGb4eh78qMeta || {},
    component: () => import("/opt/buildhome/repo/pages/test.vue")
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/dashboard",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/dashboard/",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubGZr1UkTkg3
  }
]