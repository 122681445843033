import * as cheerio from "cheerio";

export const extractMetaData = (html) => {
  const $ = cheerio.load(html);
  const metaData = {};

  // Extracting title (both standard and OG)
  const ogTitle = $('meta[property="og:title"]').attr("content");
  metaData.title = ogTitle || $("title").text();

  // Extracting description (both standard and OG)
  const ogDescription = $('meta[property="og:description"]').attr("content");
  metaData.description = ogDescription || $('meta[name="description"]').attr("content");

  // Extracting image (OG)
  metaData.image = $('meta[property="og:image"]').attr("content");

  return metaData;
};
