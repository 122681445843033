import { defineAsyncComponent } from 'vue'
export default {
  base: defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/base.vue")),
  collection: defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/collection.vue")),
  custom: defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/custom.vue")),
  dashboard: defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/dashboard.vue")),
  default: defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/default.vue")),
  empty: defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/empty.vue")),
  "get-started": defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/get-started.vue")),
  overview: defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/overview.vue")),
  setting: defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/setting.vue"))
}